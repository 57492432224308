@import "../../global";

$disappear: opacity 0.1s ease-in;

.Project {
  margin: 0.25rem;

  .secondary-title {
    height: calc(100% - 1rem);
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 700;
    padding: 0.5rem;
    transition: $disappear;
  }

  .preview {
    height: 12rem;
    width: 12rem;
    position: relative;
    border-radius: $border-radius;
    border-color: #eb9322;
    border-width: 0.17rem;
    border-style: solid;
    overflow: hidden;

    .screen {
      height: 100%;
      width: 100%;
      background-color: rgba(2, 2, 2, 0.5);
      z-index: 6;
      position: absolute;
      transition: $disappear;

      &:hover {
        background-color: rgba(2, 2, 2, 0);

        .secondary-title {
          opacity: 0;
        }
      }
    }

    img {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      height: 100%;
      z-index: 5;
    }
  }
}

@media only screen and (max-width: $mobile-width) {
  .Project {
    .preview {
      height: 8rem;
      width: 8rem;
    }
  }
}
