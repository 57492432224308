@import '../../global';

.Showcase {
  position: relative;
  top: 50%;
  height: fit-content;
  width: 50%;
  transform: translateY(-50%);
  margin: auto;
  z-index: 4;
  padding: 1rem;
  border-radius: $border-radius;
  background-color: rgba(2, 2, 2, 0.2);

  .top { 
    max-height: 50%;
    width: 100%;
  }

  .bottom {
    height: 35%;
  }

  .link-wrapper {
    float: right;
    font-size: 1.5rem;
    .link {
      height: 1.5rem;
      width: 1.5rem;
      padding: .25rem;
      vertical-align: middle;
    }
  }

  .title-wrapper {
    margin: 1rem 0 1rem 0;
  }

  .description-wrapper {
    border-radius: $border-radius;
    background-color: $content-background-color;
    padding: .5rem;
    height: 82%;

    .content {
      color: $primary-color;
    }
  }

  .footer {
    width: 100%;
    height: 3rem;
    position: relative;

    .back-button {
      position: absolute;
      bottom: 0;
      right: 0;
  
      .back-image {
        height: 2rem;
        width: 2rem;
        background-image: url('../../images/back.svg');
        background-repeat: no-repeat;
        background-size: cover;
  
        &:hover {
          background-image: url('../../images/back-shadow.svg');
        }
      }
    }
  }
}

@media only screen and (max-width: $mobile-width) {
  .Showcase {
    margin-top: 3%;
    height: fit-content;
    max-height: 91%;
    width: 85%;
    overflow-y: auto;
    overflow-x: hidden;

    .top {
      height: fit-content;
      img {
        width: 100%;
      }
    }
  }
}
