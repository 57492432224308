@import "../../global";

.Profile {
  position: relative;
  top: 50%;
  width: 60%;
  max-height: 91%;
  transform: translateY(-50%);
  margin: auto;
  z-index: 4;
  display: flex;
  flex-direction: row;
  padding: 1.5rem 1rem;
  border-radius: $border-radius;
  background-color: rgba(2, 2, 2, 0.2);
}

.portrait-wrapper {
  display: flex;
  flex-direction: column;
  width: 50%;
  gap: 0.75rem;

  .name {
    font-size: 2rem;
    font-weight: 700;
    color: $secondary-color;
    text-align: center;
  }
}

.portrait {
  width: 15rem;
  height: 15rem;
  border-radius: 50%;
  border-color: $primary-color;
  border-width: 0.25rem;
  border-style: solid;
  overflow: hidden;
  margin: auto;
}

.avatar {
  width: 100%;
}

.links {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.details {
  background-color: $content-background-color;
  border-radius: $border-radius;
  padding: 0.5rem;

  .about-me {
    margin-left: 1rem;
    color: $secondary-color;
    margin: 0.5rem;
    display: flex;
    flex-direction: column;
    gap: 0.75rem;
  }
}

.right {
  width: 45%;
  margin-left: 5%;

  .view-selector {
    display: flex;
    flex-direction: row;
    height: 10%;

    .title {
      flex-grow: 1;
      cursor: pointer;
      overflow: hidden;

      &.selected {
        background-color: $content-background-color;
        border-radius: $border-radius $border-radius 0 0;

        &:after {
          content: "";
          display: block;
          width: 50%;
          padding-top: 0.1rem;
          border-bottom: 0.2rem solid $secondary-color;
          animation: slideOut 0.25s 1 ease-in forwards;
        }

        &.right-tab:after {
          animation: slideIn 0.25s 1 ease-in forwards;
        }
      }

      &:focus {
        outline: 0;
      }
    }
  }

  .projects {
    display: flex;
    flex-direction: column;
    padding: 1rem;
    height: 84%;
    justify-content: space-around;
    border-radius: 0 $border-radius $border-radius $border-radius;
    background-color: $content-background-color;

    &.invisible {
      display: none;
    }

    &.right-tab {
      border-radius: $border-radius 0 $border-radius $border-radius;
    }

    .row {
      display: flex;
      flex-direction: row;
      justify-content: space-evenly;
    }
  }
}

@keyframes slideIn {
  from {
    animation-timing-function: ease-out;
    transform: translateX(-50%);
  }
  to {
    animation-timing-function: ease-out;
    transform: translateX(50%);
  }
}

@keyframes slideOut {
  from {
    animation-timing-function: ease-out;
    transform: translateX(100%);
  }
  to {
    animation-timing-function: ease-out;
    transform: translateX(50%);
  }
}

@media only screen and (max-width: $mobile-width) {
  .Profile {
    margin-top: 2%;
    top: 0%;
    height: fit-content;
    max-height: 91%;
    width: 85%;
    transform: unset;
    flex-direction: column;
    overflow-y: auto;
    overflow-x: hidden;

    .portrait-wrapper {
      width: 100%;

      .portrait {
        height: 10rem;
        width: 10rem;
      }
    }

    .right {
      width: 100%;
    }
  }
}
