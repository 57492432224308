@import 'global';

* {
  margin: 0;
  padding: 0;
}

.App, .mountain {
  position: absolute; 
  top:0;
  left:0;
  right:0;
  bottom:0;
  width: 100%;
  height: 100%;
  display: block;
}

.App {
  background-image: linear-gradient(#2f1149, #2faaa9);
}

.mountain {
  z-index: 3;
  background-image: url(images/mountain.png);
  background-repeat: no-repeat;
  background-size: 100%;
  background-position: center bottom;
}

html {
  position: relative;
  height: 100%;
  overflow: hidden;
}
body {
  background: #010A24;
  position: inherit;
  height: 100%;
  overflow: hidden;
}

.shooting-star {
  height: 5%;
  width: 5%;
  position: absolute;
  .lsContainer {
    .lstar {
      background-color: $secondary-color;
    }
  }
  // -webkit-animation: shoot 5s 1 linear;
  // -moz-animation: shoot 5s 1 linear;
  // -o-animation: shoot 5s 1 linear;
  // animation: shoot 5s infinite linear;
}

.moon-wrapper {
  position: absolute;
  height: 10%;
  width: 10%;
  bottom: -200%;
  right: 20%;
  z-index: 2;
  -webkit-animation: xAxis 40s infinite ease-in;
  -moz-animation: xAxis 40s infinite ease-in;
  -o-animation: xAxis 40s infinite ease-in;
  animation: xAxis 40s infinite ease-in;
}

.moonlight {
  box-shadow: 0px 0px 350px 350px rgba(29, 72, 120, .5);
  width: 100px;
  height: 100px;
  border-radius: 100%;
  -webkit-animation: yAxis 40s infinite ease-out;
  -moz-animation: yAxis 40s infinite ease-out;
  -o-animation: yAxis 40s infinite ease-out;
  animation: yAxis 40s infinite ease-out;
}

.moon {
  box-shadow: inset 0px 0px 50px 1px #6D8F94, 0px 0px 80px 30px rgba(143, 189, 255, .7);
  width: 100px;
  height: 100px; 
  background: #ffffff;
  border-radius: 100%;
}

@-webkit-keyframes xAxis {
  50% {
    animation-timing-function: ease-in;
    transform: translateX(-1500%);
  }
}
@-moz-keyframes xAxis {
  50% {
    animation-timing-function: ease-in;
    transform: translateX(-1500%);
  }
}
@-o-keyframes xAxis {
  50% {
    animation-timing-function: ease-in;
    transform: translateX(-1500%);
  }
}
@keyframes xAxis {
  50% {
    animation-timing-function: ease-in;
    transform: translateX(-1500%);
  }
}

@-webkit-keyframes yAxis {
  50% {
    animation-timing-function: ease-out;
    transform: translateY(-4000%);
  }
}
@-moz-keyframes yAxis {
  50% {
    animation-timing-function: ease-out;
    transform: translateY(-4000%);
  }
}
@-o-keyframes yAxis {
  50% {
    animation-timing-function: ease-out;
    transform: translateY(-4000%);
  }
}
@keyframes yAxis {
  50% {
    animation-timing-function: ease-out;
    transform: translateY(-4000%);
  }
}

@-webkit-keyframes shoot {
  100% {
    animation-timing-function: ease-out;
    transform: translate(-4000%, 4000%);
  }
}
@-moz-keyframes shoot {
  100% {
    animation-timing-function: ease-out;
    transform: translate(-4000%, 4000%);
  }
}
@-o-keyframes shoot {
  100% {
    animation-timing-function: ease-out;
    transform: translate(-4000%, 4000%);
  }
}
@keyframes shoot {
  100% {
    animation-timing-function: ease-out;
    transform: translate(-4000%, 4000%);
  }
}

/* stars */
.star {
  width: 5px;
  height: 5px;
  position: relative;
  z-index: 1;
  background: white;
  border-radius: 100%;
  box-shadow: 0px 0px 20px 7px rgba(120, 130, 155, .5);
}

.sContainer {
  position: absolute;
}

.lstar {
  width: 2px;
  height: 2px;
  z-index: 1;
  background: #aaaaff;
  border-radius: 100%;
  box-shadow: 0px 0px 13px 11px rgba(120, 140, 155, .1);
}

.lsContainer {
  position: absolute;
}

/////////////////////////
///     SCROLLBAR     ///
/////////////////////////
/* width */
::-webkit-scrollbar {
  width: .3rem;
}
 
/* Handle */
::-webkit-scrollbar-thumb {
  background: $primary-color; 
  border-radius: $border-radius;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #888; 
}

.title {
  font-size: 2rem;
  color: $primary-color;
  text-align: center;
}

.secondary-title {
  font-size: 1.5rem;
  color: $secondary-color;
}