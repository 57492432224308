@import '../../global';

.Gallery {
  width: 100%;
  max-height: 30rem;
  display: flex;
  flex-direction: row;
  overflow: hidden;

  .sideBar {
    width: 25%;
    margin-right: .25rem;
    overflow-y: auto;
    overflow-x: hidden;

    .preview {
      width: 95%;
      margin: .25rem 0 0 0;
      display: flex;
      flex-grow: 1;
      border-radius: .25rem;
      border-color: $primary-color;
      border-width: .2rem;
      border-style: solid;

      &:focus {
        outline: 0;
      }

      &.selected {
        border-color: $secondary-color;
      }

      .preview-image {
        width: 100%;
      }
    }
  }

  .view {
    max-width: 80%;
    margin: 0 auto;
    overflow-y: auto;

    .selected-image {
      width: 100%;
    }
  }
}