@import "../../global";

.SocialLink {
  border-radius: 1rem;

  img {
    height: 2.75rem;
    width: 2.75rem;
    padding: 0.25rem;
  }

  &:hover {
    background-color: rgba(47, 18, 74, 0.7);
    transition: 0.2s ease-in;
  }
}

@media only screen and (max-width: $mobile-width) {
  .SocialLink {
    img {
      height: 2rem;
      width: 2rem;
    }
  }
}
